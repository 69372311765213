<template>
  <el-container class="shopList">
    <div>
      <!-- <el-header class="box add">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>悦享每食</el-breadcrumb-item>
          <el-breadcrumb-item>订单管理</el-breadcrumb-item>
        </el-breadcrumb>
      </el-header> -->
      <div style="padding: 0 20px 20px">
        <div>
          <div class="df">
            <div style="flex: 0 0 300px">
              <el-input
                placeholder="导入文件名称"
                v-model="query.keyword"
                class="input-with-select"
                clearable
                @clear="clear"
              >
              </el-input>
            </div>
            <!-- <el-select
              style="margin-left: 20px"
              v-model="query.status"
              placeholder="请选择"
              @change="getList"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select> -->
            <el-button
              style="margin-left: 20px"
              type="primary"
              class="general_bgc general_border cfff"
              @click="search"
              >查询
            </el-button>
            <!-- <el-button
              style="margin-left: 20px"
              type="primary"
              class="general_bgc general_border cfff"
              @click="clear"
              >清空搜索
            </el-button> -->
            <el-upload
              class="upload"
              action="#"
              :multiple="false"
              ref="upload"
              :limit="1"
              :show-file-list="false"
              :before-upload="beforeUpload"
              style="float: right"
            >
              <el-button type="success" style="margin-left: 20px"
                >批量导入</el-button
              >
            </el-upload>
            <el-button
              style="margin-left: 20px"
              type="warning"
              class="general_bgc general_border cfff"
              @click="getTemplate"
              >自定义模板
            </el-button>
          </div>
        </div>
      </div>
      <div style="padding: 0 20px">
        <el-table
          border
          :data="tableData"
          style="width: 100%"
          v-loading="loading"
        >
          <el-table-column type="index" label="序号" width="55">
          </el-table-column>
          <el-table-column prop="name" label="导入文件名称" width="180">
          </el-table-column>
          <el-table-column prop="total" label="导入总数量" width="180">
          </el-table-column>
          <el-table-column prop="successTotal" label="导入成功数量" width="180">
          </el-table-column>
          <!-- <el-table-column prop="status" label="订单状态" width="180">
            <template slot-scope="scope">
              <p
                :style="
                  scope.row.status === '0000' ? 'color:green' : 'color:red'
                "
              >
                {{ scope.row.status === "0000" ? "已完成" : "用餐中" }}
              </p>
            </template>
          </el-table-column> -->

          <el-table-column prop="uploadTime" label="导入时间" width="180">
            <template slot-scope="scope">
              {{ scope.row.uploadTime.replace("T", " ") }}
            </template>
          </el-table-column>
          <el-table-column prop="userName" label="操作人" width="180">
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <el-button
                type="text"
                class="disib blue"
                size="mini"
                @click="handleDowload(scope.row)"
              >
                导入文件查看
              </el-button>
              <el-button
                type="text"
                class="disib blue"
                size="mini"
                @click="handleError(scope.row)"
              >
                重名商品处理
              </el-button>
              <el-button
                type="text"
                class="disib blue"
                size="mini"
                @click="downloadError(scope.row)"
              >
                异常商品查看
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="i-page fr disib mt20">
          <el-pagination
            background
            :page-size="18"
            layout="total,prev, pager, next"
            :total="total"
            :current-page="currentPage"
            @current-change="currentChange1"
          >
          </el-pagination>
        </div>
      </div>

      <el-dialog
        title="自定义上传模板"
        :visible.sync="showTemplate"
        width="520px"
        @close="closeTemplate"
      >
        <div style="width: 320px; padding-left: 40px">
          <el-form
            ref="ruleForm"
            label-position="left"
            label-width="110px"
            :model="formTemplate"
            :rules="tempRules"
          >
            <el-form-item label="产品名称" prop="goodsName">
              <el-input type="number" v-model="formTemplate.goodsName">
                <template slot="prepend">产品名称在第</template>
                <template slot="append">列</template>
              </el-input>
            </el-form-item>
            <el-form-item label="批号" prop="batchNumber">
              <el-input type="number" v-model="formTemplate.batchNumber">
                <template slot="prepend">批号在第</template>
                <template slot="append">列</template></el-input
              >
            </el-form-item>
            <el-form-item label="金额" prop="money">
              <el-input type="number" v-model="formTemplate.money">
                <template slot="prepend">金额在第</template>
                <template slot="append">列</template>
              </el-input>
            </el-form-item>
            <el-form-item label="数量" prop="num">
              <el-input type="number" v-model="formTemplate.num">
                <template slot="prepend">数量在第</template>
                <template slot="append">列</template>
              </el-input>
            </el-form-item>
            <el-form-item label="订单号" prop="orderSn">
              <el-input type="number" v-model="formTemplate.orderSn">
                <template slot="prepend">订单号在第</template>
                <template slot="append">列</template>
              </el-input>
            </el-form-item>
            <el-form-item label="流水号" prop="trxSn">
              <el-input type="number" v-model="formTemplate.trxSn">
                <template slot="prepend">流水号在第</template>
                <template slot="append">列</template>
              </el-input>
            </el-form-item>
            <el-form-item label="日期" prop="time">
              <el-input type="number" v-model="formTemplate.time">
                <template slot="prepend">日期在第</template>
                <template slot="append">列</template>
              </el-input>
            </el-form-item>
            <el-form-item label="从第几行开始" prop="row">
              <el-input type="number" v-model="formTemplate.row">
                <template slot="prepend">从第</template>
                <template slot="append">行开始(含表头)</template>
              </el-input>
            </el-form-item>
          </el-form>
          <div class="df aic jcc" style="width: 400px">
            <el-button type="primary" @click="submitForm">提交 </el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog title="导入异常" :visible.sync="showError1" width="70%">
        <div style="min-height: 600px">
          <div class="df dialog-title">重名商品</div>
          <el-table
            :data="errorInfo1"
            style="width: 100%"
            :cell-class-name="setCellName"
          >
            <el-table-column type="expand">
              <template slot-scope="props">
                <div style="padding: 0 20px">
                  <el-table
                    border
                    :data="props.row.goodsInfo"
                    style="width: 100%"
                    id="out-table"
                  >
                    <el-table-column
                      type="index"
                      align="center"
                      width="60"
                      label="序号"
                    ></el-table-column>
                    <el-table-column
                      prop="gtin"
                      min-width="120"
                      :show-overflow-tooltip="true"
                      label="商品编码"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      prop="name"
                      label="商品名"
                      min-width="120"
                      :show-overflow-tooltip="true"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="manufacturer"
                      min-width="120"
                      :show-overflow-tooltip="true"
                      label="生产商"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      prop="stock"
                      min-width="120"
                      :show-overflow-tooltip="true"
                      label="库存(kg)"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      prop="num"
                      min-width="120"
                      :show-overflow-tooltip="true"
                      label="数量"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      prop="type"
                      min-width="120"
                      :show-overflow-tooltip="true"
                      label="类型"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      label="操作"
                      width="180"
                      fixed="right"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-button
                          type="text"
                          class="disib"
                          size="mini"
                          @click="handleTally1(props.row.id, scope.row.id)"
                          >导入该商品</el-button
                        >
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="商品名称" prop="goodsName">
            </el-table-column>
            <el-table-column label="数量" prop="num"> </el-table-column>
            <el-table-column
              label="商品金额"
              prop="money"
              v-if="formTemplate.money !== ''"
            ></el-table-column>
            <el-table-column
              label="批号"
              prop="batchNumber"
              v-if="formTemplate.batchNumber !== ''"
            >
            </el-table-column>
            <el-table-column
              label="订单号"
              prop="orderSn"
              v-if="formTemplate.orderSn !== ''"
            >
            </el-table-column>
            <el-table-column
              label="流水号"
              prop="trxSn"
              v-if="formTemplate.trxSn !== ''"
            >
            </el-table-column>
            <el-table-column
              label="时间"
              prop="time"
              v-if="formTemplate.time !== ''"
            >
            </el-table-column>
            <el-table-column label="状态" prop="status">
              <template slot-scope="scope">
                <p
                  :style="scope.row.status === 1 ? 'color:green' : 'color:red'"
                >
                  {{ scope.row.status === 1 ? "导入完成" : "待确认" }}
                </p>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-dialog>
      <el-dialog title="导入异常" :visible.sync="showError" width="70%">
        <div style="min-height: 600px">
          <template
            v-if="
              errorInfo && errorInfo.errorInfo && errorInfo.errorInfo.length
            "
          >
            <div class="df dialog-title">异常信息</div>
            <el-table :data="errorInfo.errorInfo" style="width: 100%">
              <el-table-column label="商品名称" prop="goodsName">
              </el-table-column>
              <el-table-column label="数量" prop="count"> </el-table-column>
              <el-table-column
                label="商品金额"
                prop="money"
                v-if="formTemplate.money !== ''"
              ></el-table-column>
              <el-table-column
                label="批号"
                prop="batchNumber"
                v-if="formTemplate.batchNumber !== ''"
              >
              </el-table-column>
              <el-table-column
                label="订单号"
                prop="orderSn"
                v-if="formTemplate.orderSn !== ''"
              >
              </el-table-column>
              <el-table-column
                label="流水号"
                prop="trxSn"
                v-if="formTemplate.trxSn !== ''"
              >
              </el-table-column>
              <el-table-column
                label="时间"
                prop="time"
                v-if="formTemplate.time !== ''"
              >
              </el-table-column>
              <el-table-column label="错误信息" prop="message">
              </el-table-column>
            </el-table>
          </template>
          <template
            v-if="errorInfo && errorInfo.noInfo && errorInfo.noInfo.length"
          >
            <div class="df dialog-title">未查找到商品</div>
            <el-table :data="errorInfo.noInfo" style="width: 100%">
              <el-table-column label="商品名称" prop="goodsName">
              </el-table-column>
              <el-table-column label="数量" prop="count"> </el-table-column>
              <el-table-column
                label="商品金额"
                prop="money"
                v-if="formTemplate.money !== ''"
              ></el-table-column>
              <el-table-column
                label="批号"
                prop="batchNumber"
                v-if="formTemplate.batchNumber !== ''"
              >
              </el-table-column>
              <el-table-column
                label="订单号"
                prop="orderSn"
                v-if="formTemplate.orderSn !== ''"
              >
              </el-table-column>
              <el-table-column
                label="流水号"
                prop="trxSn"
                v-if="formTemplate.trxSn !== ''"
              >
              </el-table-column>
              <el-table-column
                label="时间"
                prop="time"
                v-if="formTemplate.time !== ''"
              >
              </el-table-column>
              <el-table-column label="错误信息" prop="message">
              </el-table-column>
            </el-table>
          </template>
          <template
            v-if="
              errorInfo && errorInfo.noRealInfo && errorInfo.noRealInfo.length
            "
          >
            <div class="df dialog-title">无商品名</div>
            <el-table :data="errorInfo.noRealInfo" style="width: 100%">
              <el-table-column label="商品名称" prop="goodsName">
              </el-table-column>
              <el-table-column label="数量" prop="count"> </el-table-column>
              <el-table-column
                label="商品金额"
                prop="money"
                v-if="formTemplate.money !== ''"
              ></el-table-column>
              <el-table-column
                label="批号"
                prop="batchNumber"
                v-if="formTemplate.batchNumber !== ''"
              >
              </el-table-column>
              <el-table-column
                label="订单号"
                prop="orderSn"
                v-if="formTemplate.orderSn !== ''"
              >
              </el-table-column>
              <el-table-column
                label="流水号"
                prop="trxSn"
                v-if="formTemplate.trxSn !== ''"
              >
              </el-table-column>
              <el-table-column
                label="时间"
                prop="time"
                v-if="formTemplate.time !== ''"
              >
              </el-table-column>
              <el-table-column label="错误信息" prop="message">
              </el-table-column>
            </el-table>
          </template>
          <template
            v-if="errorInfo && errorInfo.repeated && errorInfo.repeated.length"
          >
            <div class="df dialog-title">重名商品</div>
            <el-table :data="errorInfo.repeated" style="width: 100%">
              <el-table-column type="expand">
                <template slot-scope="props">
                  <div style="padding: 0 20px">
                    <el-table
                      border
                      :data="props.row.goodsInfo"
                      style="width: 100%"
                      id="out-table"
                    >
                      <el-table-column
                        type="index"
                        align="center"
                        width="60"
                        label="序号"
                      ></el-table-column>
                      <el-table-column
                        prop="gtin"
                        min-width="120"
                        :show-overflow-tooltip="true"
                        label="商品编码"
                        align="center"
                      ></el-table-column>
                      <el-table-column
                        align="center"
                        prop="name"
                        label="商品名"
                        min-width="120"
                        :show-overflow-tooltip="true"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="manufacturer"
                        min-width="120"
                        :show-overflow-tooltip="true"
                        label="生产商"
                        align="center"
                      ></el-table-column>
                      <el-table-column
                        prop="stock"
                        min-width="120"
                        :show-overflow-tooltip="true"
                        label="库存(kg)"
                        align="center"
                      ></el-table-column>
                      <el-table-column
                        prop="num"
                        min-width="120"
                        :show-overflow-tooltip="true"
                        label="数量"
                        align="center"
                      ></el-table-column>
                      <el-table-column
                        prop="type"
                        min-width="120"
                        :show-overflow-tooltip="true"
                        label="类型"
                        align="center"
                      ></el-table-column>
                      <el-table-column
                        label="操作"
                        width="180"
                        fixed="right"
                        align="center"
                      >
                        <template slot-scope="scope">
                          <el-button
                            type="text"
                            class="disib"
                            size="mini"
                            @click="handleTally(props.row.id, scope.row.id)"
                            >导入该商品</el-button
                          >
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="商品名称" prop="goodsName">
              </el-table-column>
              <el-table-column label="数量" prop="count"> </el-table-column>
              <el-table-column
                label="商品金额"
                prop="money"
                v-if="formTemplate.money !== ''"
              ></el-table-column>
              <el-table-column
                label="批号"
                prop="batchNumber"
                v-if="formTemplate.batchNumber !== ''"
              >
              </el-table-column>
              <el-table-column
                label="订单号"
                prop="orderSn"
                v-if="formTemplate.orderSn !== ''"
              >
              </el-table-column>
              <el-table-column
                label="流水号"
                prop="trxSn"
                v-if="formTemplate.trxSn !== ''"
              >
              </el-table-column>
              <el-table-column
                label="时间"
                prop="time"
                v-if="formTemplate.time !== ''"
              >
              </el-table-column>
            </el-table>
          </template>
        </div>
      </el-dialog>

      <!-- <el-dialog title="设置" :visible.sync="showSetting" width="30%">
        <div class="df jca mb20 f20 fwb">
          <div>门槛金额</div>
          <div>返现百分比</div>
        </div>
        <div
          v-for="(item, index) in setForm.shopWelfareDtos"
          :key="index"
          style="border-top: 1px solid #dcdcdc; padding: 10px"
          class="df jca pr"
        >
          <div>
            <el-input
              placeholder="请输入门槛金额"
              v-model="item.sillMoney"
              type="number"
              clearable
              style="width: 100%"
            >
            </el-input>
          </div>
          <div class="df aic">
            <el-input
              placeholder="请输入返现百分比"
              v-model="item.percent"
              type="number"
              clearable
              style="width: 100%"
            >
            </el-input>
            <div class="f25">
              <span>%</span>
            </div>
          </div>

          <div
            style="
              position: absolute;
              right: 0;
              top: 50%;
              margin-top: -15px;
              cursor: pointer;
            "
            class="df jca"
          >
            <div
              v-if="index === setForm.shopWelfareDtos.length - 1"
              @click="handleAdd(1)"
            >
              <i
                class="el-icon-circle-plus-outline"
                style="font-size: 30px; color: #409eff"
              ></i>
            </div>
            <div v-else>
              <i
                class="el-icon-circle-plus-outline"
                style="font-size: 30px; color: white"
              ></i>
            </div>
            <div v-if="index !== 0" @click="handleAdd(2, index)">
              <i
                class="el-icon-remove-outline"
                style="font-size: 30px; color: red"
              ></i>
            </div>
            <div v-else>
              <i
                class="el-icon-remove-outline"
                style="font-size: 30px; color: white"
              ></i>
            </div>
          </div>
        </div>
        <div
          style="display: flex; justify-content: space-around; margin-top: 50px"
        >
          <el-button type="info" size="medium" @click="showSetting = false"
            >取消</el-button
          >
          <el-button type="primary" size="medium" @click="submit"
            >确认</el-button
          >
        </div>
      </el-dialog> -->
      <!-- <el-dialog title="充值密码设置" :visible.sync="showSettingPd" width="30%">
        <el-form
          :rules="pdRules"
          ref="setPdForm"
          :model="setPdForm"
          label-position="right"
          label-width="200px"
        >
          <el-form-item label="原密码:" prop="money" v-if="hasPd">
            <el-input
              placeholder="请输入原密码"
              v-model="setPdForm.oldPayPassword"
              type="number"
              clearable
              style="width: 60%; float: left"
              show-password
            >
            </el-input>
          </el-form-item>
          <el-form-item label="新密码:" prop="money">
            <el-input
              placeholder="请输入新密码"
              v-model="setPdForm.payPassword1"
              type="number"
              clearable
              style="width: 60%; float: left"
              show-password
            >
            </el-input>
          </el-form-item>
          <el-form-item label="确认新密码:" prop="money">
            <div style="display: flex; flex-direction: column">
              <div>
                <el-input
                  placeholder="请确认新密码"
                  v-model="setPdForm.payPassword2"
                  type="number"
                  clearable
                  style="width: 60%; float: left"
                  show-password
                >
                </el-input>
              </div>
              <div
                style="font-size: 12px; color: red; text-align: left"
                v-show="showyz"
              >
                两次输入的密码不一致
              </div>
            </div>
          </el-form-item>
          <div
            style="
              display: flex;
              justify-content: space-around;
              margin-top: 50px;
            "
          >
            <el-button type="info" size="medium" @click="showSettingPd = false"
              >取消</el-button
            >
            <el-button type="primary" size="medium" @click="submitPd"
              >确认</el-button
            >
          </div>
        </el-form>
      </el-dialog> -->
    </div>
  </el-container>
</template>
<script>
import api from "@/util/extra-api";
import md5 from "js-md5";

export default {
  data() {
    let validatePass = (rule, value, callback) => {
      if (!this.formData.money && !this.formData.welfareMoney) {
        callback(new Error("请至少填写实充金额或赠送金额其中之一"));
      } else {
        this.$refs["form"].clearValidate();
        callback();
      }
    };
    return {
      loading: false,
      showError1: false,
      errorInfo1: [],
      showTemplate: false,
      showError: false,
      formTemplate: {
        goodsName: "",
        batchNumber: "",
        money: "",
        num: "",
        orderSn: "",
        trxSn: "",
        time: "",
        row: "",
      },
      tempRules: {
        goodsName: [
          { required: true, message: "请填写商品名称所在列", trigger: "blur" },
        ],
        num: [{ required: true, message: "请填写数量所在列", trigger: "blur" }],
        row: [
          { required: true, message: "请填写从第几行开始", trigger: "blur" },
        ],
      },
      tableData: [],
      currentPage: 1,
      allCurrentPage: 1,
      total: 0,
      query: {
        page: 1,
        limit: 18,
        companyId: null,
        keyword: "",
      },
      showInsider: false,
      showRecharge: false,
      showRechargeList: false,
      formData: {
        accountId: null,
        money: null,
        welfareMoney: null,
        companyId: null,
        payPassword: null,
        type: 0,
      },
      formData1: {
        accountId: null,
        insider: false,
        companyId: null,
        payPassword: null,
        discount: 0,
      },
      // pdRules: {},
      rechargeList: [],
      importQuery: {
        importId: null,
        page: 1,
        limit: 100,
      },
      rechargeTotal: 0,
      rechargeCurrentPage: 1,
      showSetting: false,
      showSettingPd: false,
      setForm: {
        shopWelfareDtos: [
          {
            sillMoney: null,
            percent: null,
          },
        ],
        companyId: null,
      },
      setPdForm: {
        oldPayPassword: null,
        payPassword1: null,
        payPassword2: null,
      },
      reduceData: {
        accountId: null,
        companyId: null,
        money: null,
        payPassword: null,
        type: 1,
      },
      showReduce: false,
      hasPd: false,
      showyz: false,
      flowId: null,
      options: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "0001",
          label: "用餐中",
        },
        {
          value: "0000",
          label: "已完成",
        },
      ],
      goodsOptions: [],
      goodsOptionsData: [],
      chooseValue: null,
      goodsNum: 1,
      goodsId: null,
      payPassword: null,
      companyId: null,
      errorInfo: [],
    };
  },
  mounted() {
    this.companyId = localStorage.getItem("company_id"); //获取当前企业
    this.query.companyId = localStorage.getItem("company_id"); //获取当前企业
    this.closeTemplate();
    // console.log(localStorage.getItem("user"));
    // console.log(JSON.parse(localStorage.getItem("user")).user_id);
    this.getList();
  },
  methods: {
    setCellName({ row, column, rowIndex, columnIndex }) {
      if (row.status === 1 && columnIndex === 0) {
        return "expand";
      } else {
        return "";
      }
    },
    handleError(row) {
      this.importQuery.importId = row.id;
      api.get("/v1/pc/tally/errorList", this.importQuery).then((res) => {
        if (res.code == 200) {
          if (res.data.length) {
            this.errorInfo1 = res.data;
            this.showError1 = true;
          } else {
            this.$message.warning("无重复商品");
          }
        }
      });
    },
    handleTally(errorId, goodsId) {
      api
        .get("/v1/pc/tally/handleData", {
          errorId,
          goodsId,
          companyId: this.companyId,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("导入成功");
            this.$set(
              this.errorInfo,
              "repeated",
              this.errorInfo.repeated.filter((v) => v.id !== errorId)
            );
          }
        });
    },
    handleTally1(errorId, goodsId) {
      api
        .get("/v1/pc/tally/handleData", {
          errorId,
          goodsId,
          companyId: this.companyId,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("导入成功");
            api.get("/v1/pc/tally/errorList", this.importQuery).then((res) => {
              if (res.code == 200) {
                if (res.data.length) {
                  this.errorInfo1 = res.data;
                  this.showError1 = true;
                } else {
                  this.$message.warning("无重复商品");
                }
              }
            });
          }
        });
    },
    handleDowload(row) {
      window.open(row.url);
    },
    closeTemplate() {
      api
        .get("/v1/pc/tally/excelInfo", { companyId: this.companyId })
        .then((res) => {
          if (!res.data) {
            this.formTemplate = {
              goodsName: "",
              batchNumber: "",
              money: "",
              num: "",
              orderSn: "",
              trxSn: "",
              time: "",
              row: "",
            };
          } else {
            for (let key in res.data) {
              if (res.data[key] == -1) {
                this.formTemplate[key] = "";
              } else {
                this.formTemplate[key] = res.data[key] + 1;
              }
            }
          }
        });
    },
    getTemplate() {
      api
        .get("/v1/pc/tally/excelInfo", { companyId: this.companyId })
        .then((res) => {
          if (res.code == 200) {
            if (!res.data) {
              this.formTemplate = {
                goodsName: "",
                batchNumber: "",
                money: "",
                num: "",
                orderSn: "",
                trxSn: "",
                time: "",
                row: "",
              };
            } else {
              for (let key in res.data) {
                if (res.data[key] == -1) {
                  this.formTemplate[key] = "";
                } else {
                  this.formTemplate[key] = res.data[key] + 1;
                }
              }
            }
            this.showTemplate = true;
          }
        });
      // this.showTemplate = true;
    },
    submitForm() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let formTemplate = JSON.parse(JSON.stringify(this.formTemplate));
          console.log(formTemplate);
          for (let key in formTemplate) {
            if (formTemplate[key] == "") {
              formTemplate[key] = -1;
            } else {
              formTemplate[key] = Number(formTemplate[key]) - 1;
            }
          }
          console.log(formTemplate);
          api
            .post(
              `/v1/pc/tally/excelSetting?companyId=${this.companyId}`,
              formTemplate
            )
            .then((res) => {
              if (res.code == 200) {
                this.$message.success("提交成功");
                this.showTemplate = false;
              }
            });
        } else {
          return false;
        }
      });
    },
    submitUpload(file) {
      //console.log('上传'+this.files.name)
      if (!file || !file.name) {
        this.$message.warning("请选择要上传的文件！");
        return false;
      }
      let fileFormData = new FormData();

      fileFormData.append("file", file, file.name);
      fileFormData.append("company_id", this.companyId);
      fileFormData.append(
        "user_id",
        JSON.parse(localStorage.getItem("user")).user_id
      );

      // 执行上传excel
      api
        .uploadFile(
          "/v1/pc/tally/importExcel?companyId=" + this.companyId,
          fileFormData
        )
        .then((resp) => {
          if (resp.code != 200) {
            this.$message.error("excel上传失败，请重新上传");
          } else {
            if (
              [
                ...resp.data.errorInfo,
                ...resp.data.noInfo,
                ...resp.data.noRealInfo,
                ...resp.data.repeated,
              ].length > 0
            ) {
              this.errorInfo = resp.data;
              this.showError = true;
            } else {
              this.$message.success("导入成功");
            }
            this.getList();
          }
        })
        .catch((e) => {
          //console.debug(e);
          this.$message.error("excel上传失败，请重新上传");
        });
    },
    beforeUpload(file) {
      const extension2 =
        file.name.split(".")[1] === "xlsx" || file.name.split(".")[1] === "xls";
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!extension2) {
        this.$message.warning("上传模板只能是 xlsx或xls格式!");
        return;
      }
      if (!isLt10M) {
        this.$message.warning("上传模板大小不能超过 10MB!");
        return;
      }
      setTimeout(() => {
        this.submitUpload(file);
      }, 500);
      return false; // 返回false不会自动上传
    },
    downloadError(row) {
      window.open(row.errorUrl);
    },
    goodsChoose(value) {
      // console.log(value, this.chooseValue);
      this.goodsId = value[1];
    },
    search() {
      this.query.page = 1;
      this.getList();
    },
    clear() {
      this.query.page = 1;
      this.query.keyword = "";
      this.getList();
    },
    currentChange1(page) {
      this.currentPage = page;
      this.query.page = page;
      this.getList();
    },
    // currentChange2(page) {
    //   this.rechargeCurrentPage = page;
    //   this.rechargeQuery.page = page;
    //   this.handleShowRechargeList(this.rechargeQuery.id);
    // },
    // submit() {
    //   api.post("/v1/wx/shopMember/mspz", this.setForm).then((res) => {
    //     if (res.code === 200) {
    //       this.showSetting = false;
    //       this.getList();
    //     }
    //   });
    // },
    handleAdd(type, index) {
      if (type === 1) {
        this.setForm.shopWelfareDtos.push({
          sillMoney: null,
          percent: null,
        });
      } else {
        this.setForm.shopWelfareDtos.splice(index, 1);
      }
    },
    // handleShowSetting() {
    //   api
    //     .get("/v1/wx/shopMember/mspzl", { companyId: this.query.companyId })
    //     .then((res) => {
    //       if (res.code === 200 && res.data.length > 0) {
    //         this.setForm.shopWelfareDtos = res.data;
    //       }
    //     });
    //   this.showSetting = true;
    // },
    // handleShowSettingPd() {
    //   api
    //     .get("/v1/wx/user/adminma", { companyId: this.query.companyId })
    //     .then((res) => {
    //       if (res.code === 200) {
    //         this.hasPd = res.data;
    //       }
    //     });
    //   this.showSettingPd = true;
    // },
    getList() {
      this.loading = true;
      api
        .get("/v1/pc/tally/importList", this.query)
        .then((res) => {
          if (res.code === 200) {
            this.tableData = res.data;
            this.total = res.total;
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // handleInsider() {
    //   this.$refs["insiderForm"].validate((valid) => {
    //     if (valid) {
    //       let data = {
    //         payPassword: md5(this.formData1.payPassword),
    //         accountId: this.formData1.accountId,
    //         insider: this.formData1.insider,
    //         discount: this.formData1.discount,
    //         companyId: this.formData1.companyId,
    //       };
    //       api.post("/v1/wx/user/settingInsider", data).then((res) => {
    //         if (res.code == 200) {
    //           (this.showInsider = false),
    //             (this.formData1 = {
    //               accountId: null,
    //               insider: false,
    //               companyId: null,
    //               payPassword: null,
    //               discount: 0,
    //             });
    //           this.getList();
    //         }
    //       });
    //     } else {
    //       return false;
    //     }
    //   });
    // },
    // handleRecharge() {
    //   this.$refs["form"].validate((valid) => {
    //     if (valid) {
    //       let data = {
    //         payPassword: md5(this.formData.payPassword),
    //         accountId: this.formData.accountId,
    //         money: this.formData.money,
    //         companyId: this.formData.companyId,
    //         type: this.formData.type,
    //         zsMoney: this.formData.welfareMoney,
    //       };
    //       // console.log('data',data)
    //       api.post("/v1/wx/user/cz", data).then((res) => {
    //         if (res.code === 200) {
    //           this.showRecharge = false;
    //           this.formData = {
    //             accountId: null,
    //             money: null,
    //             companyId: null,
    //             payPassword: null,
    //             type: 0,
    //           };
    //           this.getList();
    //         }
    //       });
    //     } else {
    //       return false;
    //     }
    //   });
    // },
    handleShowInsider(row) {
      this.formData1.accountId = row.accountId;
      this.formData1.companyId = this.query.companyId;
      this.formData1.insider = row.insider;
      this.formData1.discount = row.discount;
      this.showInsider = true;
    },
    handleShowRecharge(id) {
      this.formData.accountId = id;
      this.formData.companyId = this.query.companyId;
      this.showRecharge = true;
    },
    handleShowReduce(id) {
      this.reduceData.accountId = id;
      this.reduceData.companyId = this.query.companyId;
      this.showReduce = true;
    },
    //   handleShowRechargeList(id) {
    //     this.flowId = id;
    //     // console.log(id, "flowId");
    //     api
    //       .get("/v1/wx/shop/getFlowDetail", { flowId: this.flowId })
    //       .then((res) => {
    //         if (res.code === 200) {
    //           this.rechargeList = res.data.map((v) => {
    //             return {
    //               ...v,
    //               reduceNum: 1,
    //             };
    //           });
    //           this.rechargeTotal = res.total;
    //           this.showRechargeList = true;
    //           api
    //             .get("/v1/wx/shop/goods/list", {
    //               companyId: this.query.companyId,
    //             })
    //             .then((res1) => {
    //               this.goodsOptionsData = res1.data;
    //               this.goodsOptions = res1.data.map((v) => {
    //                 return {
    //                   value: v.id,
    //                   label: v.typeName,
    //                   children: v.goodsData.map((item) => {
    //                     return {
    //                       value: item.goodsId,
    //                       label: item.goodsName,
    //                       disabled: item.tag === 1,
    //                     };
    //                   }),
    //                 };
    //               });
    //             });
    //         }
    //       });
    //   },
  },
};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;

  .add {
    display: flex;
    top: 0px;
    left: 30px;
    justify-content: space-between;
    align-items: center;
  }

  .active {
    background: #2ec68a;
    border-color: #2ec68a;
    color: #ffffff;
  }

  .list_tab {
    padding: 10px;
    background-color: #f5f7fa;

    .list_tag {
      right: -10px;
      padding: 2px 6px;
      border-radius: 10px;
      top: -10px;
    }
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }

  /deep/ .el-table .aa .cell {
    display: flex;
    justify-content: center;
  }
}

$color: #2194e0;
.button_hover {
  width: 80px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  text-decoration: none;
  color: #d3d7d4;
  border: 2px solid $color;
  font-size: 14px;
  display: inline-block;
  border-radius: 15px;
  transition: all 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
  margin-right: 10px;

  &:before {
    content: "";
    background-color: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 3em;
    display: block;
    position: absolute;
    top: 0;
    left: -4.5em;
    transform: skewX(-45deg) translateX(0);
    transition: none;
  }

  &:hover {
    cursor: pointer;
    background-color: $color;
    color: #fff;
    border-bottom: 4px solid darken($color, 10%);

    &:before {
      transform: skewX(-45deg) translateX(13.5em);
      transition: all 0.5s ease-in-out;
    }
  }
}

.activated {
  color: #1d953f;
}

.haccp-bg {
  transform-origin: 0px 0px 0px;
  background-image: url("../../../assets/image/mapbg.png");
  background-size: cover;
  background-position: 50% 0;
  background-color: rgb(0, 0, 0);
  min-width: auto;
  width: 100%;
  min-height: auto;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}

.card-item {
  flex: 1;
  width: 31%;
  min-width: 31%;
  max-width: 31%;
  height: 400px;
  background: transparent;
  margin-bottom: 30px;
  border: 0.5px solid aqua;
  border-radius: 6px;
}

.card-item-title {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 20px;
  background: transparent;
  text-align: center;
  font-size: 18px;
  color: #fff;
  border-bottom: 1px solid gainsboro;
}

.card-item-content {
  padding: 20px 10px;
  /*height: 20vw*/
}

.jca {
  justify-content: space-around;
}

.fwb {
  font-weight: bold;
}

/deep/ .el-table__empty-block {
  width: 100% !important;
}
.dialog-title {
  font-size: 20px;
  font-weight: bold;
}

/deep/.expand {
  .cell {
    display: none !important;
  }
}
</style>
